import queryString from 'query-string'
import pickBy from 'lodash/pickBy'
import { apiExportResponseHandler, apiResponseHandler, getHeaders } from '../../api-utils'

export const ENDPOINT_BASE_URL = '/api/admin/containers'

const cleanParams = (params) => pickBy(params, value => value !== null && value !== '')

export const getCountries = async ({
  accessTokenPromise,
}) => {
  return fetch('/api/admin/countries', {
    headers: getHeaders(await accessTokenPromise) },
  )
    .then(apiResponseHandler)
}

export const getContainers= async ({
  accessTokenPromise,
  country,
  id,
  identity_code,
  itemsPerPage,
  page,
  request_package,
  status,
  type,
}) => {
  const cleanedParams = cleanParams({
    container_type_id: type,
    country,
    id,
    identity_code,
    items_per_page: itemsPerPage,
    page,
    request_package,
    status,
  })

  const queryParams = queryString.stringify(cleanedParams, {
    arrayFormat: 'bracket',
  })

  const endpoint = `${ENDPOINT_BASE_URL}${queryParams ? `?${queryParams}` : ''}`

  return fetch(endpoint, {
    headers: getHeaders(await accessTokenPromise),
  }).then(apiResponseHandler)
}

export const bulkActivate = async ({ accessTokenPromise, fields }) => (
  fetch(`${ENDPOINT_BASE_URL}/activate`, {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'PUT',
  })
).then(apiResponseHandler)

export const bulkDeactivate = async ({ accessTokenPromise, fields }) => (
  fetch(`${ENDPOINT_BASE_URL}/deactivate`, {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'PUT',
  })
).then(apiResponseHandler)

export const updateContainer = async ({ accessTokenPromise, fields }) => (
  fetch(`${ENDPOINT_BASE_URL}/${fields.id}`, {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'PUT',
  })
).then(apiResponseHandler)

export const batchUpdateContainersName = async ({ accessTokenPromise, fields, requestPackageFilter }) => {
  const requestBody = {
    ...fields,
    request_package: requestPackageFilter,
  }
  return (
    fetch(`${ENDPOINT_BASE_URL}/batch_update`, {
      body: JSON.stringify(requestBody),
      headers: getHeaders(await accessTokenPromise),
      method: 'PUT',
    })
  ).then(apiResponseHandler)
}

export const deleteContainer = async ({ accessTokenPromise, recordId }) => (
  fetch(`${ENDPOINT_BASE_URL}/${recordId}`, {
    headers: getHeaders(await accessTokenPromise),
    method: 'DELETE',
  })
).then(apiResponseHandler)

export const createContainer = async ({ accessTokenPromise, fields }) => (
  fetch(ENDPOINT_BASE_URL, {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'POST',
  })
).then(apiResponseHandler)

export const bulkAddContainer = async ({ accessTokenPromise, fields }) => (
  fetch(`${ENDPOINT_BASE_URL}/generate`, {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'POST',
  })
).then(apiResponseHandler)

export const exportContainers = async ({
  accessTokenPromise,
  country,
  id,
  identity_code,
  request_package,
  status,
  type,
}) => {
  const queryParams = queryString.stringify(
    {
      container_type_id: type,
      country,
      id,
      identity_code,
      request_package,
      status,
    },
    {
      arrayFormat: 'bracket',
    },
  )
  const endpoint = `${ENDPOINT_BASE_URL}/export${queryParams ? `?${queryParams}` : ''}`

  return fetch(endpoint, {
    headers: {
      ...getHeaders(await accessTokenPromise),
      'content-type': 'text/csv;charset=UTF-8',
    },
    method: 'GET',
  })
    .then(apiExportResponseHandler)
}

export const importContainers = async ({ accessTokenPromise, file }) => {
  const endpoint = `${ENDPOINT_BASE_URL}/import`

  const header = getHeaders(await accessTokenPromise)
  delete header['Content-Type']

  return fetch(endpoint, {
    body: file,
    headers: header,
    method: 'POST',
  })
    .then(apiResponseHandler)
}
