import React from 'react'
import { Col, Typography, Row } from 'antd'
import logo from '../../assets/images/loop-blue-logo.png'

const { Title } = Typography

const HomePage = () => (
  <Col style={{ alignItems: 'center', height: '100vh', justifyContent: 'center', textAlign: 'center' }}>
    <Col span={24}>
      <Row justify="center" type="flex">
        <Col>
          <img
            alt="Loop logo"
            name="homepageLogoSelenium"
            src={logo}
            style={{ height: '115px' }}
          />
        </Col>
      </Row>

      <Row justify="center" type="flex">
        <Col>
          <Title level={2} name="homepageTitleSelenium">
            Loop Integrated Model - Container Management System
          </Title>
        </Col>
      </Row>
    </Col>
  </Col>
)

export default HomePage
