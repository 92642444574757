import React from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  Typography,
} from 'antd'

const { TextArea } = Input
const { Text, Title } = Typography
const { confirm } = Modal

const ModalWithForm = ({
  errorMessage,
  hideModal,
  onSubmit,
  status,
  sumSelectedContainers,
}) => {
  const [form] = Form.useForm()

  const isInactive = status === 'inactive'
  const statusIsUpdatedTo = isInactive ? 'ready_to_use' : 'deactivated'

  const handleError = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const handleOk = () => {
    confirm({
      content: 'This action cannot be undone.',
      onOk() {
        const actionType = isInactive ? 'ACTIVATE' : 'DEACTIVATE'

        onSubmit(actionType)
          .catch(handleError)
      },
      title: 'Are you sure?',
    })
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelContainerBulkUpdateBtnSelenium'}}
      okButtonProps={{ name: 'changeContainerStatusInBulkSelenium' }}
      okText="Update"
      onCancel={hideModal}
      onOk={handleOk}
      open={true}
      title="Change status in bulk"
    >
      <Col style={{ marginBottom: '20px', marginTop: '10px'}}>
        <Title level={4}>({sumSelectedContainers}) Selected containers</Title>

        <Descriptions
          bordered
          column={2}
          size="middle"
        >
          <Descriptions.Item label="from status:" span={2}>
            {status}
          </Descriptions.Item>

          <Descriptions.Item label="to status:">
            {statusIsUpdatedTo}
          </Descriptions.Item>
        </Descriptions>
      </Col>

      {status === 'ready_to_use' && (
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item
            label="Deactivation comment"
            name="deactivation_comment"
            style={{ marginBottom: '0' }}
          >
            <TextArea style={{ minHeight: 110 }} />
          </Form.Item>

        </Form>
      )}

      <Text mark>Please check the filtered list properly before the update.</Text>

      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

ModalWithForm.propTypes = {
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.string,
  sumSelectedContainers: PropTypes.number.isRequired,
}

export default ModalWithForm
