import React from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
} from 'antd'
import { TypeSelector } from '../../components'

const { confirm } = Modal

const ModalWithForm = ({
  containerTypeList,
  countriesList,
  errorMessage,
  hideModal,
  onSubmit,
}) => {
  const [form] = Form.useForm()
  const { getFieldsValue, isFieldsTouched, validateFields } = form

  const handleError = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const handleOk = () => {
    const fieldsValue = getFieldsValue()
    const isFormUpdated = isFieldsTouched()
    const selectedType = containerTypeList.find(type => type.id === fieldsValue.container_type_id)

    isFormUpdated ? (
      validateFields()
        .then(() => {
          confirm({
            content: `You are about to generate
           ${fieldsValue.number}
           QR code(s) for
           ${selectedType.name} for
           ${selectedType.country}.
           This request cannot be revoked,
           please check the input parameters
           before submitting. You will receive
           the list of generated QR codes attached
           to an email.`
            ,
            onOk() {
              const actionType = 'BULK_ADD'

              onSubmit(actionType, { ...fieldsValue })
                .catch(handleError)
            },
            title: 'Are you sure?',
          })
        })
        .catch(handleError)
    ) : (
      hideModal()
    )
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelBulkAddContainerBtnSelenium'}}
      okButtonProps={{ name: 'addContainersInBulkSelenium' }}
      okText="Add"
      onCancel={hideModal}
      onOk={handleOk}
      open={true}
      title="Add containers in bulk"
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="Type"
          name="container_type_id"
          rules={[{
            message: 'Container type is required!',
            required: true,
          }]}
        >
          <TypeSelector types={containerTypeList} />
        </Form.Item>

        <Form.Item
          label="Number of containers"
          name="number"
          rules={[
            {
              message: 'Number of containers is required!',
              required: true,
            },
            {
              message: 'Number of containers should contain only numbers!',
              pattern: new RegExp('^[0-9]*$'),
            },
          ]}
        >
          <InputNumber max={1000000} min={1} style={{ width: '100%' }} type="number" />
        </Form.Item>

        <Form.Item
          label="Status"
        >
          <Input defaultValue="inactive" disabled />
        </Form.Item>

        <Form.Item
          extra="With this name you can easily find the QR codes generated in bulk, e.g. when you want to make a status change in bulk."
          label="Batch name"
          name="request_package"
          rules={[{
            message: 'Batch name is required!',
            required: true,
          }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Manufacturing date"
          name="manufacturing_date"
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>

        <Form.Item
          label="Manufacturing company"
          name="manufacturing_company"
        >
          <Input />
        </Form.Item>
      </Form>
      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

ModalWithForm.propTypes = {
  containerTypeList: PropTypes.array.isRequired,
  countriesList: PropTypes.array.isRequired,
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default ModalWithForm
