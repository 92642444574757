import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Alert, Form, Input, Modal, Select } from 'antd'
import { containerTypes, containerTypeTypes, formPropTypes } from '../../types'
import { TypeSelector } from '../../components'
import { CONTAINER_STATUSES } from '../../constants'

const { Option } = Select
const { TextArea } = Input

const StatusSelector = forwardRef((props, ref) => (
  <Select id="status" name="statusSelectSelenium" ref={ref} {...props}>
    {CONTAINER_STATUSES.sort().map((status) =>
      <Option key={status} name="statusOptionSelectSelenium" value={status}>{status}</Option>,
    )}
  </Select>
))

const ModalWithForm = ({
  containerTypeList,
  errorMessage,
  hideModal,
  isModalLoading,
  onSubmit,
  setModalLoading,
  selectedContainer,
}) => {
  const [form] = Form.useForm()
  const isUpdate = !!selectedContainer
  const { getFieldsValue, isFieldsTouched, validateFields } = form

  const handleError = errorInfo => {
    setModalLoading(false)

    console.log('Failed:', errorInfo)
  }

  const handleOk = () => {
    const isFormUpdated = isFieldsTouched()

    if (isFormUpdated) {
      setModalLoading(true)

      const fieldsValue = (isUpdate)
        ? getFieldsValue(['deactivation_comment', 'status'])
        : getFieldsValue(['country', 'identity_code', 'container_type_id'])

      const actionType = isUpdate ? 'UPDATE' : 'CREATE'
      const id = isUpdate ? selectedContainer.id : undefined

      validateFields()
        .then(() => onSubmit(actionType, { ...fieldsValue, id }))
        .catch(handleError)
    } else {
      hideModal()
    }
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelContainerModalBtnSelenium'}}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: isUpdate ? 'updateContainerSelenium' : 'addContainerSelenium' }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={hideModal}
      onOk={handleOk}
      open={true}
      title={isUpdate ? 'Update container' : 'Create container'}
    >
      <Form
        form={form}
        initialValues={{
          container_type_id: isUpdate ? selectedContainer.type.id : undefined,
          deactivation_comment: isUpdate ? selectedContainer.deactivation_comment : undefined,
          identity_code: isUpdate ? selectedContainer.identity_code : undefined,
          status: isUpdate ? selectedContainer.status : undefined,
        }}
        layout="vertical"
      >
        <Form.Item
          label="Type"
          name="container_type_id"
          rules={[{
            message: 'Container type is required!',
            required: true,
          }]}
        >
          <TypeSelector disabled={isUpdate} types={containerTypeList} />
        </Form.Item>

        <Form.Item
          label="Identity code"
          name="identity_code"
          rules={[{
            message: 'Identity code is required!',
            required: true,
          }]}
        >
          <Input disabled={isUpdate} />
        </Form.Item>

        {isUpdate &&
          <Form.Item
            label="Status"
            name="status"
            rules={[{
              message: 'Status is required!',
              required: true,
            }]}
          >
            <StatusSelector />
          </Form.Item>
        }

        <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.status === currentValues.status}>
          {({ getFieldValue }) => (
            (getFieldValue('status') === 'deactivated' && isUpdate)
              ? (
                <Form.Item
                  label="Deactivation comment"
                  name="deactivation_comment"
                >
                  <TextArea autoSize={{ maxRows: 5 , minRows: 3 }} />
                </Form.Item>
              )
              : null
          )}
        </Form.Item>
      </Form>
      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

ModalWithForm.propTypes = {
  containerTypeList: PropTypes.arrayOf(containerTypeTypes),
  errorMessage: PropTypes.string,
  form: formPropTypes,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedContainer: containerTypes,
  setModalLoading: PropTypes.func.isRequired,
}

export default ModalWithForm
