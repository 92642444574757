import React from 'react'
import { BorderOuterOutlined, ContainerOutlined, HomeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const menuElements = [
  {
    icon: <HomeOutlined />,
    key: '/',
    label: (<Link to="/">Dashboard</Link>),
    name: 'dashboardSelenium',
    permission: [],
  },
  {
    icon: <BorderOuterOutlined />,
    key: '/container-types',
    label: (<Link to="/container-types">Container types</Link>),
    name: 'containerTypesSelenium',
    permission: ['read:container_types'],
  },
  {
    icon: <ContainerOutlined />,
    key: '/containers',
    label: (<Link to="/containers">Containers</Link>),
    name: 'containersSelenium',
    permission: ['read:containers'],
  },
]

export default menuElements
