import React from 'react'
import { useAsync } from 'react-async'
import { Route, Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Layout } from 'antd'
import {
  ContainerDetailsPage,
  ContainersPage,
  ContainerTypesPage,
  Error404Page,
  HomePage,
} from './pages'
import {
  AuthenticationLayer,
  ErrorMessage,
  Profile,
  Sidebar,
} from './components'
import { getConfigs } from './api'
import { Auth0Provider } from './auth0'

export const browserHistory = createBrowserHistory()

const onRedirectCallback = appState => {
  // Temporary Firefox workaround
  window.location.hash = window.location.hash // eslint-disable-line no-self-assign

  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  )
}

const App = () => {
  const { data, error, isLoading } = useAsync({
    promiseFn: getConfigs,
  })

  const { Header, Content, Footer } = Layout

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data} />
  if (isLoading) return null

  return (
    <Auth0Provider
      authorizationParams={{
        audience: data?.audience,
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
      clientId={data?.clientId}
      domain={data?.domain}
      onRedirectCallback={onRedirectCallback}
    >
      <Router history={browserHistory}>
        <AuthenticationLayer>
          <Layout style={{ minHeight: '100vh' }}>
            <Sidebar />

            <Layout>
              <Header style={{ background: '#fff', padding: '0 16px' }}>
                <Profile />
              </Header>

              <Content style={{ margin: '16px' }}>
                <div
                  style={{ background: '#fff', minHeight: 360, padding: 24 }}
                >
                  <Switch>
                    <Route component={HomePage} exact path="/" />
                    <Route
                      component={ContainerDetailsPage}
                      exact
                      path="/containers/:id"
                    />
                    <Route
                      component={ContainersPage}
                      exact
                      path="/containers"
                    />
                    <Route
                      component={ContainerTypesPage}
                      exact
                      path="/container-types"
                    />
                    <Route component={Error404Page} />
                  </Switch>
                </div>
              </Content>

              <Footer style={{ textAlign: 'center' }}>
                Loop CoMS {new Date().getFullYear()}
              </Footer>
            </Layout>
          </Layout>
        </AuthenticationLayer>
      </Router>
    </Auth0Provider>
  )
}

export default App
