import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Alert, Form, Input, Modal, Select } from 'antd'
import { containerTypes, formPropTypes } from '../../types'
import { CountrySelector } from '../../components'
import { CONTAINER_TYPES, CONTAINER_TYPE_SETTINGS } from '../../constants'

const { Option } = Select
const { TextArea } = Input

const TypeSelector = forwardRef((props, ref) => (
  <Select ref={ref} {...props}>
    {CONTAINER_TYPES.sort().map((type) =>
      <Option key={type} name="typeOptionSelenium" value={type}>{type}</Option>,
    )}
  </Select>
))
const TypeSettingsSelector = forwardRef((props, ref) => (
  <Select ref={ref} {...props}>
    {CONTAINER_TYPE_SETTINGS.sort().map((type) =>
      <Option key={type} name="typeSettingOptionSelenium" value={type}>{type}</Option>,
    )}
  </Select>
))

const ModalWithForm = ({
  countriesList,
  errorMessage,
  hideModal,
  isModalLoading,
  onSubmit,
  setModalLoading,
  selectedContainerType,
}) => {
  const [form] = Form.useForm()
  const isUpdate = !!selectedContainerType
  const { getFieldsValue, isFieldsTouched, validateFields } = form

  const handleError = errorInfo => {
    setModalLoading(false)

    console.log('Failed:', errorInfo)
  }

  const handleOk = () => {
    const isFormUpdated = isFieldsTouched()

    if (isFormUpdated) {
      setModalLoading(true)

      const fieldsValue = getFieldsValue(['country', 'type', 'name', 'sku', 'description', 'settings'])
      const actionType = isUpdate ? 'UPDATE' : 'CREATE'
      const id = isUpdate ? selectedContainerType.id : undefined

      validateFields()
        .then(() => onSubmit(actionType, { ...fieldsValue, id }))
        .catch(handleError)
    } else {
      hideModal()
    }
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelContainerTypeBtnSelenium' }}
      confirmLoading={isModalLoading}
      okButtonProps={{ name: isUpdate ? 'updateContainerTypeSelenium' : 'addContainerTypeSelenium' }}
      okText={isUpdate ? 'Update' : 'Add'}
      onCancel={hideModal}
      onOk={handleOk}
      open={true}
      title={isUpdate ? 'Update container type' : 'Create container type'}
    >
      <Form
        form={form}
        initialValues={{
          country: isUpdate ? selectedContainerType.country : undefined,
          description: isUpdate ? selectedContainerType.description : undefined,
          name: isUpdate ? selectedContainerType.name : undefined,
          settings: isUpdate ? selectedContainerType.settings : [],
          sku: isUpdate ? selectedContainerType.sku : undefined,
          type: isUpdate ? selectedContainerType.type : undefined,
        }}
        layout="vertical"
      >
        <Form.Item
          label="Type"
          name="type"
          rules={[{
            message: 'Container type is required!',
            required: true,
          }]}
        >
          <TypeSelector disabled={isUpdate} />
        </Form.Item>

        <Form.Item
          className="country"
          label="Country"
          name="country"
          rules={[{
            message: 'Country is required!',
            required: true,
          }]}
        >
          <CountrySelector countries={countriesList} disabled={isUpdate} />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[{
            message: 'Container name is required!',
            required: true,
          }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
        >
          {({ getFieldValue }) => (
            <Form.Item
              label="Package SKU"
              name="sku"
              rules={[
                {
                  message: 'Package SKU is required!',
                  required: getFieldValue('type') === 'multi_usage_package',
                },
                {
                  message: 'Package SKU should contain only English letters and numbers!',
                  pattern: new RegExp('^[A-Za-z0-9]+$'),
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          className="settings"
          label="Settings"
          name="settings"
        >
          <TypeSettingsSelector allowClear mode="multiple" />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
        >
          <TextArea style={{ minHeight: 110 }} />
        </Form.Item>
      </Form>
      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

ModalWithForm.propTypes = {
  countriesList: PropTypes.array,
  errorMessage: PropTypes.string,
  form: formPropTypes,
  hideModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  selectedContainerType: containerTypes,
  setModalLoading: PropTypes.func.isRequired,
}

export default ModalWithForm
