import React, { useState } from 'react'
import { useAsync } from 'react-async'
import {
  Button,
  Col,
  Empty,
  notification,
  Popconfirm,
  Row,
  Table,
  Typography,
} from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { createContainerType, deleteContainerType, getContainerTypes, updateContainerType } from './api'
import { getCountries } from './../containers/api'
import ContainerTypesModalForm from './ContainerTypesModal'
import { Can, ErrorMessage } from '../../components'
import { routePropTypes } from '../../types'
import { useAuth0 } from '../../auth0'
import './ContainerTypesPage.css'

const { Title } = Typography

const ContainerTypes = () => {
  const [ isContainerTypeModalVisible, setContainerTypeModalVisibility ] = useState(false)
  const [ isModalLoading, setModalLoading ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState()
  const [ selectedContainerType, setSelectedContainerType ] = useState(null)
  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const { data, error, isLoading, reload } = useAsync({
    accessTokenPromise,
    promiseFn: getContainerTypes,
  })

  const countriesData = useAsync({
    accessTokenPromise,
    promiseFn: getCountries,
  })
  const countries = countriesData?.data?.sort() ?? []

  if (error) return <ErrorMessage networkError={error} />
  if (data && data.error) return <ErrorMessage dataError={data.error} />

  const handleSuccess = (messageOptions = {}) => {
    reload()
    setContainerTypeModalVisibility(false)
    setErrorMessage(null)

    notification.open({
      description: 'Container type is added successfully',
      duration: 3,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      message: 'Success',
      ...messageOptions,
    })
  }

  const handleFailure = (error, messageOptions = {}) => {
    console.log(error)
    setErrorMessage(error.message)
    setModalLoading(false)

    notification.open({
      description: 'Container type creation is failed.',
      duration: 3,
      icon: <CloseCircleTwoTone twoToneColor="#f5222d" />,
      message: 'Failure',
      ...messageOptions,
    })
  }

  const handleFormSubmit = (actionType, payload) => {
    switch (actionType) {
    case 'CREATE':
      return createContainerType({ accessTokenPromise, fields: payload })
        .then(() => handleSuccess())
        .catch(handleFailure)

    case 'UPDATE':
      return updateContainerType({ accessTokenPromise, fields: payload })
        .then(() => handleSuccess({ description: 'Container type is updated successfully.' }))
        .catch((error) => handleFailure(error, { description: 'Container type update is failed.' }))

    default:
      throw Error('Unhandled action type')
    }
  }

  const handleDeleteContainerType = (recordId) => {
    deleteContainerType({ accessTokenPromise, recordId })
      .then(() => handleSuccess({ description: 'Container type is removed successfully.' }))
      .catch((error) => handleFailure(error, { description: 'Container type removal is failed.' }))
  }

  const handleOpenModal = (selectedContainerType) => {
    setSelectedContainerType(selectedContainerType)
    setErrorMessage(null)
    setModalLoading(false)
    setContainerTypeModalVisibility(true)
  }

  const columns = [
    {
      className: 'idSelenium',
      dataIndex: 'id',
      key: 'id',
      title: 'ID',
      width: 100,
    },
    {
      className: 'countrySelenium',
      dataIndex: 'country',
      key: 'country',
      title: 'Country',
    },
    {
      className: 'typeSelenium',
      dataIndex: 'type',
      key: 'type',
      title: 'Type',
    },
    {
      className: 'nameSelenium',
      dataIndex: 'name',
      key: 'name',
      title: 'Name',
    },
    {
      className: 'skuSelenium',
      dataIndex: 'sku',
      key: 'sku',
      title: 'Package SKU',
    },
    {
      className: 'descriptionSelenium',
      dataIndex: 'description',
      key: 'description',
      title: 'Description',
    },
    {
      className: 'settingsSelenium',
      dataIndex: 'settings',
      key: 'settings',
      title: 'Settings',
    },
    {
      className: 'numberOfContainersSelenium',
      dataIndex: 'number_of_containers',
      key: 'number_of_containers',
      title: 'Number of containers',
    },
    {
      className: 'actionSelenium',
      key: 'action',
      render: (record) => (
        <span className="action-list">
          <Can
            requiredPermission="update:container_types"
            yes={() => (
              <Button
                className="action-button update-button"
                name="updateContainerTypeSelenium"
                onClick={() => handleOpenModal(record)}
                size="small"
              >
                Update
              </Button>
            )}
          />
          <Can
            requiredPermission="delete:container_types"
            yes={() => (
              <Popconfirm
                cancelText="No"
                className="delete-popconfirm"
                disabled={record.number_of_containers > 0}
                okText="Yes"
                onConfirm={() => handleDeleteContainerType(record.id)}
                title="Are you sure you want to delete this container type?"
              >
                <Button
                  className="action-button delete-button"
                  danger
                  disabled={record.number_of_containers > 0}
                  ghost
                  name="deleteContainerTypeSelenium"
                  size="small"
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          />
        </span>
      ),
      title: 'Action',
      width: 130,
    },
  ]

  const containerTypes = (data && data.container_types) || []

  return (
    <div>
      <Title>Container types</Title>
      <Row>
        <Col span={24} style={{ marginBottom: '20px', textAlign: 'right' }}>
          <Can
            requiredPermission="create:container_types"
            yes={() => (
              <Button
                name="createContainerTypeSelenium"
                onClick={() => handleOpenModal(null)}
                type="primary"
              >
                Add
              </Button>
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            bordered
            className="containerTypeTableSelenium"
            columns={columns}
            dataSource={containerTypes}
            loading={isLoading}
            locale={{ emptyText: <Empty description={'No container type'} /> }}
            pagination={false}
            rowKey="id"
            width="auto"
          />
        </Col>
      </Row>

      {isContainerTypeModalVisible && (
        <ContainerTypesModalForm
          countriesList={countries}
          errorMessage={errorMessage}
          hideModal={() => setContainerTypeModalVisibility(false)}
          isModalLoading={isModalLoading}
          onSubmit={handleFormSubmit}
          selectedContainerType={selectedContainerType}
          setModalLoading={setModalLoading}
          setVisibility={setContainerTypeModalVisibility}
        />
      )}
    </div>
  )
}

ContainerTypes.propTypes = {
  ...routePropTypes,
}

export default ContainerTypes
