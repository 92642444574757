import { apiResponseHandler, getHeaders } from '../../api-utils'

export const ENDPOINT_BASE_URL = '/api/admin/container_types'

export const getContainerTypes = async ({
  accessTokenPromise,
}) => {
  return fetch(ENDPOINT_BASE_URL, {
    headers: getHeaders(await accessTokenPromise) },
  )
    .then(apiResponseHandler)
}

export const updateContainerType = async ({ accessTokenPromise, fields }) => (
  fetch(`${ENDPOINT_BASE_URL}/${fields.id}`, {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'PUT',
  })
).then(apiResponseHandler)

export const deleteContainerType = async ({ accessTokenPromise, recordId }) => (
  fetch(`${ENDPOINT_BASE_URL}/${recordId}`, {
    headers: getHeaders(await accessTokenPromise),
    method: 'DELETE',
  })
).then(apiResponseHandler)

export const createContainerType = async ({ accessTokenPromise, fields }) => (
  fetch(ENDPOINT_BASE_URL, {
    body: JSON.stringify({
      ...fields,
    }),
    headers: getHeaders(await accessTokenPromise),
    method: 'POST',
  })
).then(apiResponseHandler)
