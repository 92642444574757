/**
 * Returns the response for success, thows error on failure.
 *
 * @param {Object} result - fetch request's result
 *
 * @returns {Object} The parsed JSON when it exists - or the detailed error.
 */
const apiExportResponseHandler = async (result) => {
  const x = result.status

  switch (true) {
  case (x >= 500):
    throw Error(result.statusText)
  case (x >= 400):
    const { details, error } = await result.json()
    const fields = Object.keys(details)

    const errorMessages = fields.map((field) => (
      `${field} - ${details[field]}`
    ))

    throw Error(`${result.status} ${error}: \n${errorMessages.join('\n')}`)
  case (x === 204):
    return {}
  default:
    return result
  }
}

export default apiExportResponseHandler
