import { apiResponseHandler, getHeaders } from '../../api-utils'

export const ENDPOINT_BASE_URL = '/api/admin/containers'

export const getContainer= async ({ accessTokenPromise, containerId }) => {
  const endpoint = `${ENDPOINT_BASE_URL}/${containerId}`

  return fetch(endpoint, {
    headers: getHeaders(await accessTokenPromise),
  })
    .then(apiResponseHandler)
}

export const getContainerEvents= async ({ accessTokenPromise, containerId }) => {
  const endpoint = `${ENDPOINT_BASE_URL}/${containerId}/events`

  return fetch(endpoint, {
    headers: getHeaders(await accessTokenPromise),
  })
    .then(apiResponseHandler)
}
