import React, { useState } from 'react'
import {
  Button,
  message,
  notification,
  Row,
  Upload,
} from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone, UploadOutlined } from '@ant-design/icons'
import { importContainers } from './api'
import { useAuth0 } from '../../auth0'
import './ContainersPage.css'

const ContainerUploader = () => {
  const { getTokenSilently } = useAuth0()
  const accessTokenPromise = getTokenSilently()

  const [state, setState] = useState({
    fileList: [],
    uploading: false,
  })

  const handleUpload = () => {
    const formData = new FormData()
    state.fileList.forEach(file => {
      formData.append('file', state.fileList[0].originFileObj)
    })

    setState({
      ...state,
      uploading: true,
    })

    importContainers({ accessTokenPromise, file: formData })
      .then(({ message }) => {
        setState({
          fileList: [],
          uploading: false,
        })

        notification.open({
          description: message,
          duration: 3,
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          message: 'Success',
        })
      })
      .catch(() => {
        setState({
          ...state,
          uploading: false,
        })

        notification.open({
          description: 'Containers import failed.',
          duration: 3,
          icon: <CloseCircleTwoTone twoToneColor="#f5222d" />,
          message: 'Failure',
        })
      })
  }

  const props = {
    beforeUpload: file => {
      if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
        message.error(`${file.name}(${file.type}) is not a csv file.`)
      } else {
        return false
      }
    },
    fileList: state.fileList,
    onChange: ({fileList}) => {
      if (fileList.length > 0 && (fileList[0].type === 'text/csv' || fileList[0].type === 'application/vnd.ms-excel')) {
        setState({ ...state, fileList: fileList})
      }
    },
    onRemove: file => {
      setState(state => {
        const index = state.fileList.indexOf(file)
        const newFileList = state.fileList.slice()
        newFileList.splice(index, 1)
        return {
          fileList: newFileList,
        }
      })
    },
  }

  return (
    <Row>
      <Upload {...props}>
        <Button disabled={state.fileList.length > 0} icon={<UploadOutlined />} style={{ marginLeft: '20px' }}>Import CSV</Button>
      </Upload>
      <Button
        className="start-import-button"
        disabled={state.fileList.length === 0}
        loading={state.uploading}
        onClick={handleUpload}
        style={{ marginLeft: '20px' }}
        type="primary"
      >
        {state.uploading ? 'Importing' : 'Start Import'}
      </Button>
    </Row>
  )
}

export default ContainerUploader
