import PropTypes from 'prop-types'

export const containerType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  number_of_containers: PropTypes.number,
  number_of_cycles: PropTypes.number,
  sku: PropTypes.string,
  type: PropTypes.string,
})

export const container = PropTypes.shape({
  country: PropTypes.string,
  deactivation_comment: PropTypes.string,
  id: PropTypes.number,
  identity_code: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.oneOfType([
    containerType,
    PropTypes.string,
  ]),
})
