import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

const { Option } = Select

const CountrySelector = forwardRef((props, ref) => (
  <Select ref={ref} {...props}>
    {props.countries.map((country) =>
      <Option key={country} name="countryOptionSelenium" value={country}>{country}</Option>,
    )}
  </Select>
))

CountrySelector.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.string),
}

export default CountrySelector
