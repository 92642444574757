import React from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  Typography,
} from 'antd'

const { Title } = Typography
const { confirm } = Modal

const ModalWithForm = ({
  errorMessage,
  hideModal,
  name,
  onSubmit,
  sumSelectedContainers,
}) => {
  const [form] = Form.useForm()
  const { getFieldsValue, isFieldsTouched, validateFields } = form

  const handleError = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const handleOk = () => {
    const fieldsValue = getFieldsValue()
    const isFormUpdated = isFieldsTouched()

    isFormUpdated ? (
      validateFields()
        .then(() => {
          confirm({
            content: 'This action cannot be undone.',
            onOk() {
              const actionType = 'BATCH_UPDATE'

              onSubmit(actionType, { ...fieldsValue })
                .catch(handleError)
            },
            title: 'Are you sure?',
          })
        })
        .catch(handleError)
    ) : (
      hideModal()
    )
  }

  return (
    <Modal
      cancelButtonProps={{ name: 'cancelBatchNameBulkUpdateBtnSelenium'}}
      okButtonProps={{ name: 'changeBatchNameInBulkSelenium' }}
      okText="Update"
      onCancel={hideModal}
      onOk={handleOk}
      open={true}
      title="Change name in bulk"
    >
      <Col style={{ marginBottom: '20px', marginTop: '10px'}}>
        <Title level={4}>({sumSelectedContainers}) Selected containers</Title>

        <Descriptions
          bordered
          column={2}
          size="middle"
        >
          <Descriptions.Item label="from name:" span={2}>
            {name}
          </Descriptions.Item>

        </Descriptions>
      </Col>

      <Form form={form} layout="vertical">
        <Form.Item
          label="New name"
          name="new_request_package"
          rules={[{
            message: 'Batch name is required!',
            required: true,
          }]}
        >
          <Input />
        </Form.Item>
      </Form>

      {
        errorMessage &&
          <Alert
            description={errorMessage}
            message="Error"
            showIcon
            type="error"
          />
      }
    </Modal>
  )
}

ModalWithForm.propTypes = {
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  name: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  sumSelectedContainers: PropTypes.number.isRequired,
}

export default ModalWithForm
