import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  Select,
} from 'antd'
import { containerTypeTypes } from '../../types'

const { Option } = Select

const TypeSelector = forwardRef((props, ref) => {
  const handleFilter = (input, option) => (
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  )

  return (
    <Select
      filterOption={handleFilter}
      ref={ref}
      showSearch
      {...props}
    >
      {props.types.map((element) =>
        <Option
          key={element.id}
          name="typeOptionSelenium"
          value={element.id}
        >
          {element.name}
        </Option>,
      )}
    </Select>
  )
})

TypeSelector.propTypes = {
  types: PropTypes.arrayOf(containerTypeTypes),
}

export default TypeSelector
